<template>
  <div :class="{ 'card_pizza-build': pizzaBuild, 'card_disable': data?.hasStop }" :id="'card' + getId" class="card">
    <div @click="openModal(type, getSlug, getId)" class="card_photo">
      <div v-if="data?.hasStop" class="card_disable_cover">
        <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
          <path d="M2 1.5L37.5 37" stroke="white" stroke-width="3" />
          <path d="M37.5 1.5L2 37" stroke="white" stroke-width="3" />
        </svg>
        <span>Временно<br>недоступно</span>
      </div>
      <img v-if="getImage && !client" :src="getImage" alt="Food">
      <img v-if="getImage && client" v-lazy="getImage" alt="Food">
      <!-- TODO: Персональная скидка -->
      <!-- <div class="card_promo_personal" v-if="personalPromotePage">
				<div class="card_promo_personal_star">{{ personalPromotePercent + '%' }}</div>
				<div class="card_promo_personal_text">персональная скидка</div>
			</div> -->
      <template>
        <!-- Тут был v-else -->
        <div class="card_attrs">
          <div v-if="isStickerMarketingPersonalDiscount" class="card_attrs_personal">
            персональная скидка
          </div>
          <img v-if="isStickerMarketingPercent" src="/cardicons/promote.svg" class="card_attrs_promote">
          <img v-if="pizzaBuild" src="/cardicons/twoinone.svg" class="card_attrs_promote">
          <!-- TODO: Promote Два по цене одной или че это за стикеры -->
          <!-- <img src="/cardicons/bx2.svg" class="card_attrs_x2" v-if="promoteX2"> -->
          <img v-if="isStickerMarketingNew" src="/cardicons/new.svg" class="card_attrs_promote">
          <img v-if="isStickerMarketingHit" src="/cardicons/hit-2.svg" class="card_attrs_promote">
          <img v-if="isStickerDoubling" src="/cardicons/sticker-x2.svg" class="card_attrs_promote card_attrs_promote_x2">
        </div>
        <div class="card_attrs card_attrs_right">
          <img v-if="isStickerSpicy" src="/cardicons/spicy.svg" class="card_attrs_spicy">
          <img v-if="isStickerChild" src="/cardicons/child.svg" class="card_attrs_child">
          <img v-if="isStickerVegetarian" src="/cardicons/vedgie.svg" class="card_attrs_vedgie">
        </div>
      </template>
    </div>
    <div class="card_body">
      <div v-if="getId" class="card_like">
        <Like :uuid="getId" />
      </div>
      <div class="card_title">
        <p v-if="getName" @click="openProduct(type, getSlug, getId)">
          {{ getName }}
        </p>
      </div>
      <div class="card_description">
        <p v-if="getDescription">
          {{ getDescription }}
        </p>
      </div>
      <div class="card_tools">
        <div v-if="getModifications && getModifications.length" class="card_tools_group">
          <template v-for="(v, i) in getModifications">
            <div :key="`modcard-${ getId }-${ i }-${ v.modification_id }`">
              <!-- Модификатор select -->
              <ButtonTumbler
                v-model="modificationActive"
                v-if="v.type === 'select'"
                :mini="true"
                :nameOn="v.nameOn"
                :nameOff="v.nameOff"
                :active="modificationActive"
              />
              <!-- Модификатор single -->
              <TumblerProp
                v-model="modificationActive"
                v-if="v.type === 'single'"
                :id="`cb-${ getId }-${ i }`"
              >
                {{ v.nameOn }}
              </TumblerProp>
            </div>
          </template>
        </div>
        <div v-if="getModifiedTechnicalCard" class="card_tools_weight">
          <span>Вес:</span>
          <strong>
            <icon-weight />
            {{ getModifiedTechnicalCard.weight + ' г' }}
          </strong>
        </div>
      </div>
      <div v-if="data?.hasStop" class="card_disable_btn">
        Временно недоступно
      </div>
      <div v-else-if="!pizzaBuild" class="card_submit">
        <div class="card_submit_price">
          <del v-if="getOldPrice">{{ getOldPrice }}<Ruble /></del>
          <strong>{{ getModifiedPrice }} <Ruble /></strong>
        </div>
        <!--				<div class="card_bonus_add" v-if="getBonusAdd">+{{getBonusAdd}}&nbsp;₽</div>-->
        <button @click="remoteCartPush" class="card_submit_incart">
          В корзину
          <strong>
            <font-awesome-icon :icon="['fas', 'shopping-cart']" />
            <!--						<font-awesome-icon :icon="['fas', 'shopping-basket']" />-->
          </strong>
        </button>
      </div>
      <div v-else class="card_submit">
        <div class="card_submit_price">
          <strong>{{ 'от ' + getPrice }} <Ruble /></strong>
        </div>
        <Button @click="goToPizzaBuild" class="button_border">
          Собрать
        </Button>
      </div>
    </div>
    <Modal
      :open="modal"
      @close="modal = false"
      class="modal_card_wrap"
      initial-width="1139px"
    >
      <ModalCard :id="getId" />
    </Modal>
  </div>
</template>

<script>
import global from '@/mixins/global'
import ButtonTumbler from './../UI/ButtonTumbler'
import TumblerProp from './../UI/TumblerProp'
import Button from './../UI/Button'
import Ruble from './../UI/Ruble'
import Like from './../UI/Like'
import Modal from './Modal/Modal'
import ModalCard from './Modal/ModalCard'

export default {
  name: 'Card',
  components: { ButtonTumbler, Ruble, Like, Button, TumblerProp, Modal, ModalCard },
  mixins: [global],
  props: {
    data: {
      type: Object,
      default: () => null,
    },
    type: String,
    pizzaBuild: {
      type: Boolean,
      default: () => false,
    },
    stopModal: {
      type: Boolean,
      default: () => false,
    },
  },
  data () {
    return {
      client: false,
      modificationActive: false,
      modifications: [],
      modal: false,
    }
  },
  computed: {
    getId () { return this.data ? this.data.id : 0 },
    getName () { return this.data ? this.data.name : '' },
    getSlug () { return this.data ? this.data.slug : '' },
    getDescription () { return this.data ? this.data.description : '' },
    getH1 () { return this.data ? this.data.h1 : 'h1' },
    getPrice () { return this.data ? this.data.price : null },
    getModifiedPrice () {
      return this.modificationActive ? this.getModifications[0].actualPrice : this.getPrice
    },
    getOldPrice () { return this.data ? this.data.oldPrice : null },
    getBonusAdd () {
		  if (this.data) {
        return this.modificationActive
		  					? this.getModifications[0].foxCoinsAdd
		  					: this.data.foxCoinsAdd
      }
		  return 0
    },
    getStickerType () { return this.data ? this.data.stickerType : '' },
    getStickerMarketing () { return this.data ? this.data.stickerMarketing : '' },
    getImage () { return this.data ? this.data.image : '' },
    getComposition () { return this.data ? this.data.composition : '' },
    getModifications () { return this.data ? this.data.modifications : [] },
    getTechnicalCard () { return this.data ? this.data.technicalCard : null },
    getModifiedTechnicalCard () {
      return this.modificationActive ? this.getModifications[0].actualCharacteristicTechCard : this.getTechnicalCard
    },
    /**
		 * Стикеры
		 */
    isStickerChild () { return this.getStickerType === 'child' },
    isStickerSpicy () { return this.getStickerType === 'spicy' },
    isStickerVegetarian () { return this.getStickerType === 'vegetarian' },
    /**
		 * Маркетинговые стикеры
		 */
    isStickerMarketingNew () { return this.getStickerMarketing === 'new' },
    isStickerMarketingHit () { return this.getStickerMarketing === 'hit' },
    isStickerDoubling () { return this.data && this.data.isDoubling },
    isStickerMarketingPercent () { return this.getStickerMarketing === 'percent' },
    isStickerMarketingPersonalDiscount () { return this.getStickerMarketing === 'personalDiscount' },
  },
  mounted () {
    this.client = true
  },
  created () {
    this.updateModifications()
  },
  methods: {
    openProduct (category, slug, id) {
      localStorage.setItem('openedProduct', id)
      this.$router.push({ name: 'menu-name-single', params: { name: this.data.categories[0].slug, single: slug, id } })
    },
    openModal (category, slug, id) {
      if (this.data?.hasStop) {
        return
      }
      if (window.innerWidth >= 768 && !this.stopModal) {
        this.modal = true
      } else if (this.pizzaBuild) {
        this.goToPizzaBuild()
      } else {
        this.openProduct(category, slug, id)
      }
    },
    changeFullHalf (index) {
      this.isFullHalf.forEach((val, i) => {
        if (index === i) { val.active = true } else { val.active = false }
      })
    },
    pushlike () {

    },
    getOffset (el) {
      let _x = 0
      let _y = 0
      while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft
        _y += el.offsetTop - el.scrollTop
        el = el.offsetParent
      }
      return { top: _y, left: _x }
    },
    remoteCartPush () {
      this.addToCart(
        this.data,
        this.modificationActive,
      )
      this.animateSale(`#card${this.getId}`)
    },
    updateModifications () {
      this.modifications = this.getModifications ? this.getModifications.map((v) => {
        return {
          id: v.modification_id || 0,
          active: v.active || false,
          nameOn: v.nameOn || '',
          nameOff: v.nameOff || '',
          price: v.actualPrice,
          technicalCard: v.actualCharacteristicTechCard,
        }
      }) : []
    },
    goToPizzaBuild () {
      this.$router.push('/pizza-construct')
    },
  },
}
</script>

<style lang="less">
.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  border-radius: 30px;
  box-shadow: 0 15px 46px -20px rgba(0, 0, 0, .15);
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &_disable {
    cursor: auto;

    &_cover {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background: rgba(233, 237, 239, 0.20);
      backdrop-filter: blur(6px);
      font-family: 'Aqum', Arial, sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: 0;
      text-align: center;
      color: #FFF;
    }

    &_btn {
      margin: 0 auto;
      width: 95%;
      max-width: 295px;
      padding: 12px 16px;
      border-radius: 14px;
      background: #F6F6F6;
      font-family: 'Roboto Slab', Arial, sans-serif;
      text-align: center;
      font-size: 17px;
      line-height: 20px;
      color: #000;
    }
  }

  & > * {
    box-sizing: border-box;
  }

  &_photo {
    width: 100%;
    height: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @menu-item--photo-bgr;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 1200px) {
      height: 213px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      object-position: center;
    }

    &_fake {
      width: 100%;
      height: 260px;
      border-radius: 30px;
      position: absolute;
    }
  }

  &_attrs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 24px;
    left: 17px;

    @media screen and (max-width: 1200px) {
      top: 20px;
      left: 14px;
    }

    & > *:not(:last-child) {
      margin-bottom: 10px;

      @media screen and (max-width: 1200px) {
        margin-bottom: 20px;
      }
    }

    &_personal {
      height: 35px;
      display: flex;
      align-items: center;
      background-color: #FF005F;
      font-family: 'Roboto Slab', Arial, sans-serif;
      font-weight: bold;
      font-size: 14px;
      color: white;
      padding: 5px 11px;
      border-radius: 10px;
      box-sizing: border-box;

      @media screen and (max-width: 1200px) {
        height: 30px;
      }
    }

    &_right {
      left: unset;
      right: 24px;
    }

    .card_attrs_promote_x2 {
      width: 50px;
    }
  }

  &_body {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    background-color: white;
    padding: 29px 27px 27px 28px;
    position: relative;

    @media screen and (max-width: 1200px) {
      padding: 20px 25px 17px 23px;
    }
  }

  &_like {
    display: inline-block;
    position: absolute;
    top: -23px;
    right: 35px;
    z-index: 1;

    @media screen and (max-width: 1200px) {
      top: -18px;
      right: 28px;
    }
  }

  &_title {
    margin-bottom: 10px;

    @media screen and (max-width: 1200px) {
      margin-bottom: 15px;
    }

    p {
      font-family: 'Aqum', Arial, sans-serif;
      font-weight: 500;
      font-size: 14px;
      color: black;
      line-height: 20px;
    }
  }

  &_description {
    min-height: 65px;
    margin-bottom: 9px;

    @media screen and (max-width: 1200px) {
      min-height: 70px;
    }

    p {
      font-family: 'Roboto', Arial, sans-serif;
      font-weight: 300;
      font-size: 14px;
      color: black;
      line-height: 18px;
    }
  }

  &_tools {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;

    &_weight {
      width: 65px;
      height: 62px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #F6F6F6;
      font-family: 'Roboto', Arial, sans-serif;
      color: black;
      border-radius: 15px;
      position: absolute;
      right: 20px;

      @media screen and (max-width: 1200px) {
        width: 44px;
        height: 42px;
        border-radius: 10px;
      }

      span {
        font-weight: 300;
        font-size: 12px;
        margin-bottom: 8px;

        @media screen and (max-width: 1200px) {
          font-size: 9px;
          margin-bottom: 7px;
        }
      }

      strong {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 12px;

        @media screen and (max-width: 1200px) {
          font-size: 8px;
        }

        svg {
          font-size: 9px;
          margin-right: 4px;

          @media screen and (max-width: 1200px) {
            font-size: 6px;
          }
        }
      }
    }

    .tumbler-prop label {
      padding: 7px 15px;
    }
  }

  &_bonus_add {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -3px;

    &:before {
      content: '';
      display: inline-block;
      background: url("/setcoins-icon.svg") no-repeat center center;
      background-size: contain;
      width: 22px;
      height: 22px;
      margin-bottom: 4px;
    }
  }

  &_submit {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_price {
      margin-top: 4px;
      display: flex;
      flex-direction: column;
      position: relative;

      del {
        display: flex;
        font-family: 'Roboto', Arial, sans-serif;
        font-size: 16px;
        color: #B7B7B7;
        // position: absolute;
        // top: -17px;
      }

      strong {
        font-family: 'Roboto', Arial, sans-serif;
        font-weight: 500;
        font-size: 30px;
        color: black;
      }
    }

    &_incart {
      display: flex;
      align-items: center;
      background-color: transparent;
      font-family: 'Roboto', Arial, sans-serif;
      font-size: 14px;
      color: black;
      padding: 0;
      border: none;
      cursor: pointer;

      strong {
        width: 61px;
        height: 61px;
        display: flex;
        align-items: center;
        justify-content: center;

        background: #E31E24;
        background: linear-gradient(180deg, #fc5964 0%, #e30615 100%);
        /*background: #E31E24 url("/icons/icon-add-to-basket.svg") no-repeat center center;
         background-size: 100% 100%;*/
        font-size: 22px;
        color: white;
        border: 8px solid #FBFBFB;
        border-radius: 50%;
        margin-left: 9px;

        @media screen and (max-width: 1200px) {
          border-width: 7px;
          font-size: 12px;
          margin-left: 6px;
        }

        svg {
          //font-size: 17px;
        }
      }
    }
  }

  &_pizza-build {
    .card {
      &_photo {
        background-color: #FFF0DC;
      }
    }
  }

  &_promo_personal {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;

    &_star {
      width: 80px;
      height: 80px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      background-image: url('/purp_star.svg');
      background-size: contain;
      background-repeat: no-repeat;
      font-family: 'Roboto Slab', Arial, sans-serif;
      font-weight: bold;
      font-size: 31px;
      text-align: center;
      color: white;
      z-index: 2;
    }

    &_text {
      height: 35px;
      display: flex;
      align-items: center;
      background-color: #FF005F;
      font-family: 'Roboto Slab', Arial, sans-serif;
      font-weight: bold;
      font-size: 14px;
      color: white;
      padding: 0 16px 0 37px;
      margin-left: -28px;
      border-radius: 10px;
      z-index: 1;
    }
  }
}

.modal_card_wrap {
  .modal_body {
    padding: 0;

    .product_col_info {
      padding-right: 35px;
    }
  }
}
</style>
