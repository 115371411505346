<template>
  <div :class="{ 'modal-card_bundle': isBundle }" class="modal-card">
    <LoaderOverScreen v-if="loading" />
    <template v-else>
      <template v-if="isBundle">
        <ProductCombo :item="item" :isInlineCard="true" />
      </template>
      <template v-else>
        <Product :item="item" :isInlineCard="true" />
      </template>
    </template>
  </div>
</template>

<script>
import Product from '@/components/page/Product/Product'
import ProductCombo from '@/components/page/Product/ProductCombo'
import LoaderOverScreen from '@/components/UI/LoaderOverScreen'

export default {
  components: { Product, ProductCombo, LoaderOverScreen },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      loading: true,
      item: null,
    }
  },
  computed: {
    isBundle () { return this.item ? this.item.type === 'bundle' : false },
  },
  created () {
    this.$axios.get(this.$store.getters['api/menuSingle'](this.id)).then((res) => {
      if (res.status === 200) {
        this.item = res.data.data
        this.loading = false
      } else {
        this.$toast.error('Ошибка при загрузке')
      }
    }).catch((err) => {
      this.$toast.error('Ошибка при загрузке')
    })
  },
}
</script>

<style lang="less">
.modal-card {
	height: 520px;

	.container {
		max-width: none;
	}

	&_bundle {
		--height: 800px;

		height: var(--height);

		.product {
			&_combo {
				height: 100%;

				&_container {
					height: 100%;
					margin-right: 0;
					margin-left: 0;
					margin-bottom: 0;
				}

				&_col {
					padding-left: 0;
					padding-right: 0;
				}

				&_row { height: 100%; }

				.combo {
					&_slider {
						height: 100%;

						.ps {
							height: var(--height);
							margin-top: 0;
							padding-top: 35px;
							padding-bottom: 43px;

							&__rail-y {
								right: 10px !important;
							}
						}
					}
				}

				&_info {
					&_inner {
						padding-right: 35px;
					}
				}
			}

			&_photo {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
	.product {
		&_container {
			padding-right: 0;
			padding-left: 0;
			margin: 0;
		}

		&_info_title {
			padding-right: 60px;
		}

		&_combo {
	  	.product_attrs_container {
				position: absolute;
				top: 25px;
				left: 25px;
				display: flex;

				.product_photo_attrs {
					position: static !important;
					margin-right: 20px;
				}
	 	 	}
		}
	}

}

</style>
